
































import Bop from "@/models/Bop";
import OperationService from "@/services/OperationService";
import { OperationType } from "@/utils/Enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import BopService from "@/services/BopService";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
    OperationComponent: () =>
      import("@/components/BopSetup/shared/OperationComponent.vue"),
  },
})
export default class OperationDiverseStep extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isEditable(): boolean {
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  }
  private operations: Array<any> = [];
  private columns: Array<any> = [];
  private operationGroupsTotalPoints: any = {};

  private get bopShort(): String {
    return `${this.bop.siteName} - ${this.bop.bopModelName?.toString().toLowerCase()} ${this.bop.bopNumber ? " # ":""}${this.bop.bopNumber ?? ""}`;
  }

  private get bop() {
    return this.$store.state.bop;
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  /**
   * Hooks
   */
  private async created() {
    this.operations = this.bop.operationDiverseGroups[0].operations;
    this.columns = [
      {
        name: "quantity",
        title: this.$t("bopSetup.operationQuantity").toString(),
        property: "quantity",
        isNotEditable: !this.isEditable,
      },
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationUnitPoints").toString(),
        formula: "operation.operationUnitValue",
        titleClass: "hidden",
        dataClass: "hidden",
      },
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.total").toString(),
        property: "totalPoints",
        formula: "{operation.operationUnitValue} * {quantity}",
      },
    ];
  }
  private onOperationChange(operationDictionary: any) {
      const operationGroupIndex = this.bop.operationDiverseGroups.findIndex(
          (og) => og.id === operationDictionary.key
    );
    const operations = operationDictionary.value;
    this.bop.operationDiverseGroups[
      operationGroupIndex
    ].operations = operations;
  }

  mounted() {
    this.operationGroupsTotalPoints = {};
  }

  private onTotalPointsChanged(totalPoints: any) {

    let result = totalPoints.value;
    this.operationGroupsTotalPoints[totalPoints.key] = result;
    if(!this.isEditable)return;
    const totals = Object.values(this.operationGroupsTotalPoints);
    // console.log(totals);
    if (!totals.length) {
        this.$store.commit("changeTotalOperationPrincipalPoints", 0);
        return;
    }
    result = <number>(
      totals.reduce(
        (accumulator, currentValue) =>
          <number>accumulator + <number>currentValue
      )
    );
    result = Math.round((result + Number.EPSILON) * 100) / 100;
    // console.log(result);
    this.$store.commit("changeTotalOperationDiversePoints", result);
  }
}
